<template>
  <div style="margin: 20px 20px">
    <h1 style="font-size: 4rem; font-weight: bolder">
      {{ specification.specification.title }}
    </h1>
    <div v-for="req in requirements" :key="req.id">
      <header v-if="req.priority === 'Heading'" class="rpt">
        <h1 v-if="req.priority === 'Heading' && ['1',1].includes(req.level)" :id="req.id">
          {{ req.section }}
        </h1>
        <h2 v-else-if="req.priority === 'Heading' && ['2',2].includes(req.level)" :id="req.id">
          {{ req.section }}
        </h2>
        <h3 v-else-if="req.priority === 'Heading' && ['3',3].includes(req.level)" :id="req.id">
          {{ req.section }}
        </h3>
        <h4 v-else-if="req.priority === 'Heading' && ['4',4].includes(req.level)" :id="req.id">
          {{ req.section }}
        </h4>
        <h5 v-else-if="req.priority === 'Heading'" :id="req.id">
          {{ req.section }}
        </h5>
        <span v-sanitized-html="req.object_text" />
      </header>
      <div v-else>
        <span v-if="req.priority !== 'Heading' && req.priority !== 'Advice'"><small>{{ req.display_id }}</small></span>
        <span v-sanitized-html="req.object_text" />
        <span v-if="req.priority !== 'Heading' && req.priority !== 'Advice'">[{{ req.priority }}]</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'Content',
  data() {
    return {
      requirements: [],
      spec: '',
    }
  },
  computed: {
    ...mapState({
      specification: state => state.specifications.selectedSpecification,
    }),
  },
  mounted() {
    console.log('Spec Full: ', this.specification)
    this.spec = this.specification.specification.id
    console.log('Spec: ', this.spec)
    this.requirements = this.specification.requirements
    console.log('Reqs on load: ', this.requirements)
  },
}
</script>
<style scoped>
  header.rpt {
    display: flex;
    align-items: baseline;
    padding: 0;
  }
  header span {
    margin-left: 1rem;
  }
</style>
