<template>
  <div>
    <b-row>
      <b-col cols="3">
        <b-form-group
          label="Page Size:"
          label-for="page-size-select"
        >
          <b-form-select v-model="selectedPageSize" :options="pageSizeOptions" class="text-capitalize" />
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group
          label="Orientation:"
          label-for="orientation-select"
        >
          <b-form-select v-model="selectedPageOrientation" :options="orientationOptions" class="text-capitalize" />
        </b-form-group>
      </b-col>
      <b-col cols="1">
        <b-button variant="outline-primary" @click="generatePDF()">
          Generate PDF
        </b-button>
      </b-col>
      <b-col cols="5">
        <b-progress :value="progress" :max="100" show-progress animated />
      </b-col>
    </b-row>
    <hr>
    <vue-html2pdf
      ref="html2Pdf"
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1200"
      :filename="specification.specification.title"
      :pdf-quality="2"
      :manual-pagination="false"
      :pdf-format="selectedPageSize"
      :pdf-orientation="selectedPageOrientation"
      pdf-content-width="800px"
      @progress="onProgress($event)"
      @hasGenerated="hasGenerated($event)"
    >
      <section slot="pdf-content">
        <RequirementsHTML2PDF_Content />
      </section>
    </vue-html2pdf>
    <div>
      <RequirementsHTML2PDF_Content />
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import VueHtml2pdf from 'vue-html2pdf'
// eslint-disable-next-line camelcase
import RequirementsHTML2PDF_Content from '@/views/RequirementsTableLegacy/RequirementsHTML2PDF_Content.vue'

export default {
  name: 'RequirementsHTML2PDF',
  components: {
    RequirementsHTML2PDF_Content,
    VueHtml2pdf,
  },
  data() {
    return {
      progress: 0,
      selectedPageSize: 'a4',
      selectedPageOrientation: 'portrait',
      pageSizeOptions: ['a0', 'a1', 'a2', 'a3', 'a4', 'letter', 'legal'],
      orientationOptions: ['portrait', 'landscape'],
    }
  },
  computed: {
    ...mapState({
      specification: state => state.specifications.selectedSpecification,
    }),
  },
  methods: {
    onProgress(event) {
      console.log('Progress: ', event)
      this.progress = event
    },
    hasGenerated(event) {
      // Not sure I need to do anything?
    },
    generatePDF() {
      this.progress = 0
      this.$refs.html2Pdf.generatePdf()
    },

  },
}
</script>
